<template>
  <section class="co-bg-striped relative px-3 py-5 sm:p-10">
    <div class="co-container-xs">
      <div class="bu-cpn-code-banner-bg w-full rounded-sm p-2.5 lg:w-[50rem]">
        <div class="bu-cpn-code-banner-inner flex flex-col gap-2 rounded-sm p-2 lg:flex-row lg:gap-5 lg:px-5 lg:py-4">
          <div class="flex w-full flex-col">
            <div class="flex flex-col items-center pb-2 lg:flex-row lg:gap-3">
              <div class="rounded-sm px-2 py-1 text-center font-bold text-[#d9534f] lg:w-[11.25rem] lg:border lg:border-[#d9534f] lg:bg-white">
                <span class="text-[1.5rem] leading-normal lg:block lg:text-[1rem] lg:tracking-[0.2em]">無料相談の</span>
                <span class="co-display-8 leading-none">割引特典</span>
              </div>
              <div class="flex w-full flex-col items-center justify-center gap-2 text-center lg:flex-row lg:items-baseline">
                <div>
                  <span class="text-[1rem] font-bold lg:text-[1.5rem]">受講料</span>
                </div>
                <div class="leading-8">
                  <span class="co-display-2 font-bold text-co-danger-500">{{ formattedAmount }}<span class="co-display-8">円</span></span>
                  <span class="co-display-4">OFF</span>
                </div>
              </div>
            </div>
            <div class="z-[1] -mb-2 block text-center text-[0.6875rem] font-bold lg:hidden">
              キャンペーンコード
            </div>
            <div class="flex items-center justify-center gap-2 rounded-sm bg-gray-200 px-4 py-2 lg:justify-between lg:py-1">
              <div class="hidden text-[0.6875rem] font-bold lg:block">
                キャンペーンコード
              </div>
              <div class="text-center text-[0.875rem] font-bold text-co-gray-500 lg:text-[0.9375rem]">
                {{ entryCampaign.code }}
              </div>
              <a
                v-if="entryCampaign?.code"
                class="text-co-gray-400 hover:text-co-gray-400"
                @click.prevent="copyToClipboard(entryCampaign.code)"
              >
                <FontAwesomeIcon
                  :icon="faCopy"
                  class="fa-fw inline size-4"
                />
              </a>
            </div>
            <div class="co-display-14 pt-1 text-co-gray-500">
              ※ キャンペーンコードは、コースのお申し込みの際にフォーム「備考」にご入力ください。
            </div>
          </div>
          <div class="bu-limit-time-box flex w-full flex-col items-center justify-center rounded-sm p-2 text-center text-co-gray-50 lg:w-56">
            <div class="hidden lg:block">
              <FontAwesomeIcon :icon="faClockNine" class="fa-fw -mb-2 inline size-8" />
            </div>
            <div class="text-[0.9375rem] font-bold">有効期限</div>
            <div v-if="leftTime > 0" class="co-display-12 font-bold">
              <span>{{ formatTime(days) }}日</span>
              <span>{{ formatTime(hours) }}時</span>
              <span>{{ formatTime(minutes) }}分</span>
              <span>{{ formatTime(seconds) }}秒</span>
            </div>
            <div class="co-display-12">
              <span class="pr-1 font-bold">{{ formattedExpiresAt }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faClockNine } from '@awesome.me/kit-65d95819ce/icons/classic/regular'
import { faCopy } from '@awesome.me/kit-65d95819ce/icons/classic/solid'

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  props: {
    entryCampaign: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      leftTime: 0,
    }
  },
  computed: {
    days() {
      return Math.floor(this.leftTime / (1000 * 60 * 60 * 24))
    },
    hours() {
      return Math.floor((this.leftTime / (1000 * 60 * 60)) % 24)
    },
    minutes() {
      return Math.floor((this.leftTime / (1000 * 60)) % 60)
    },
    seconds() {
      return Math.floor((this.leftTime / 1000) % 60)
    },
    formattedAmount(): string {
      return this.entryCampaign?.amount ? this.entryCampaign.amount.toLocaleString() : '0'
    },
    formattedExpiresAt(): string {
      if (!this.entryCampaign?.expires_at) return '-'
      const dt = new Date(this.entryCampaign.expires_at)
      const year = dt.getFullYear()
      const month = String(dt.getMonth() + 1).padStart(2, '0')
      const day = String(dt.getDate()).padStart(2, '0')
      const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土'][dt.getDay()]
      return `${year}/${month}/${day} (${dayOfWeek})`
    },
  },
  mounted() {
    if (this.entryCampaign?.expires_at) {
      this.leftTime = Date.parse(this.entryCampaign.expires_at) - new Date().getTime()
      setInterval(this.countdown, 1000)
    }
  },
  methods: {
    countdown() {
      if (this.leftTime > 0) {
        this.leftTime -= 1000
      } else {
        this.leftTime = 0
      }
    },
    copyToClipboard(text: string) {
      navigator.clipboard.writeText(text).then(() => {
        alert('キャンペーンコードをコピーしました！')
      })
    },
    formatTime(value: number): string {
      return value < 10 ? `0${value}` : value.toString()
    },
  },
  setup() {
    return {
      faCopy,
      faClockNine,
    }
  },
})
</script>

<style scoped>
.bu-cpn-code-banner-bg {
  background: linear-gradient(95deg, #D9534F 18.51%, #FC912C 75.03%);
}

.bu-cpn-code-banner-inner {
  background-image: url('~/assets/images/Te/TeCommon/Banner/TimeLimitCpnCode/bu-cpn-code-banner-bg-inner-sp.svg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  @media (min-width: 576px) {
    background-image: url('~/assets/images/Te/TeCommon/Banner/TimeLimitCpnCode/bu-cpn-code-banner-bg-inner-md.svg');
  }
  @media (min-width: 992px) {
    background-image: url('~/assets/images/Te/TeCommon/Banner/TimeLimitCpnCode/bu-cpn-code-banner-bg-inner.svg');
  }
}

.bu-limit-time-box {
  background: linear-gradient(92deg, #D9534F -44.09%, #FC912C 142.69%);
}
</style>
